export default {
  menuList:[
    { name: '首页',routeName:'notaryList',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '公证员管理',routeName:'notaryAdd',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '配置管理',routeName:'notaryAdmin',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '区域管理',routeName:'regionAdminGzt',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
    { name: '服务档案',routeName:'notaryLog',iconType:'svg',iconSrc:'/img/icons-svg/menu/service.svg',},
  ],
  displayRules:{
    /*
      *level-按Admin账号等级显示;
      *specific-指定AdminID显示;
      *specificAll-指定AdminID及下属全部显示;
      *specificHidden-指定AdminID隐藏;
      *specificHideAll-指定AdminID及下属全部隐藏;
      *默认全部展示
      *需要建立与routeName相同名称的对象
      *填入ID，对应规则生效
      *按层级判断，有一个判断结果为真则显示
    */
    'regionAdminGzt':{level:[0,1,2],specific:[],specificAll:[],specificHidden:[],specificHideAll:[],specificLogin:[]}
  }
}